var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-data-table',{class:_vm.tableClass ? _vm.tableClass : '',staticStyle:{"margin-bottom":"10px"},attrs:{"dense":_vm.dense ? _vm.dense : false,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage ? _vm.itemsPerPage : 20,"search":_vm.search,"sortBy":_vm.sortBy ? _vm.sortBy : '',"item-key":_vm.itemKey,"hide-default-header":_vm.hideHeader ? _vm.hideHeader : false,"hide-default-footer":_vm.hideFooter ? _vm.hideFooter : true,"show-group-by":false,"show-select":_vm.showSelect ? _vm.showSelect : false,"loading":_vm.loading,"multi-sort":_vm.multiSort ? _vm.multiSort : false,"loading-text":_vm.loadingText ? _vm.loadingText : 'Cargando... Por favor espere',"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-0 my-2"},[_c('v-row',{staticStyle:{"margin":"0px","padding-left":"10px"}},[_c('label',{staticStyle:{"margin":"0px","width":"auto"}},[_vm._v(_vm._s(_vm.$t('Components.AdminSimpleList.orderText')))]),_c('multiselect',{staticStyle:{"width":"auto","min-width":"100px","padding-left":"10px"},attrs:{"track-by":"id","label":"name","deselect-label":"","select-label":"","placeholder":_vm.internalOrder.name,"options":_vm.orderOptions,"multiple":false,"close-on-select":true,"clear-on-select":false,"allow-empty":false,"preserve-search":false},on:{"input":function($event){return _vm.$emit('orderEvent', _vm.internalOrder)}},model:{value:(_vm.internalOrder),callback:function ($$v) {_vm.internalOrder=$$v},expression:"internalOrder"}})],1),_c('v-spacer'),_c('span',{staticStyle:{"padding":"0px 10px"}},[_c('strong',{staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.$t('Components.AdminSimpleList.totalResults'))+" "+_vm._s(_vm.totalItems))])])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_vm._l((_vm.extraButtoms),function(buttom){return _c('v-tooltip',{key:buttom.id,attrs:{"min-width":"auto","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"title":buttom.text},on:{"click":function($event){return _vm.handleRedirect(buttom, item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(buttom.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(buttom.text))])])}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.showEdit ? _vm.showEdit : true)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon && _vm.editIcon !== '' ? _vm.editIcon : 'mdi-pencil')+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.editTooltip && _vm.editTooltip !== '' ? _vm.editTooltip : _vm.$t('Components.AdminSimpleList.editText')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.showDelete ? _vm.showDelete : false)?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Components.AdminSimpleList.deleteText')))])])]}},{key:"header.actions",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Components.AdminSimpleList.actionsText'))+" ")]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center"}),_vm._v(" "+_vm._s(_vm.$t('Components.AdminSimpleList.noData'))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_c('div',{staticClass:"text-center"}),_vm._v(" "+_vm._s(_vm.$t('Components.AdminSimpleList.noResults'))+" ")]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"text-center"}),_vm._v(" "+_vm._s(_vm.$t('Components.AdminSimpleList.loadingData'))+" ")]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"text-center pt-2",staticStyle:{"margin":"10px 0px"}},[_c('v-pagination',{attrs:{"color":"#0c2c7c","length":_vm.pages},on:{"input":function($event){return _vm.sendCurrentPage($event)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }