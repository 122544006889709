<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          {{ $t('Views.AdminUsers.title')
          }}<v-btn
            color="#0c2c7c"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >{{ $t('Views.AdminUsers.createButton') }}
          </v-btn>
        </h3>

        <v-row class="mx-0 my-2" style="width: 100%">
          <label>{{ $t('Views.AdminUsers.filterText') }}:</label>
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row class="mx-0">
              <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                <v-text-field
                  dense
                  solo
                  hide-details="auto"
                  :label="$t('Views.AdminUsers.filterSearch')"
                  v-model="filters.search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                <multiselect
                  track-by="id"
                  label="name"
                  deselect-label=""
                  select-label=""
                  :placeholder="$t('Views.AdminUsers.filterRole')"
                  v-model="filters.role"
                  :options="roles"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect>
              </v-col>
              <v-btn
                color="#0c2c7c"
                style="min-width: auto; position: absolute; right: 50px; height: 25px"
                dark
                class="px-1 py-1"
                v-if="filterActivated"
                @click.prevent="
                  filterActivated = false
                  cleanFilters()
                  getUsers()
                "
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#0c2c7c"
                    style="min-width: auto; position: absolute; right: 10px; height: 25px"
                    dark
                    class="px-1 py-1"
                    @click.prevent="
                      filterActivated = true
                      getUsers()
                    "
                    ><v-icon dark> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('Views.AdminUsers.filterButtonText') }}</span>
              </v-tooltip>
            </v-row>
          </v-form>
        </v-row>
      </div>
      <simple-list
        :key="index"
        :title="''"
        :dense="true"
        :headers.sync="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        :orderBy="orderBy"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :shiftY="1">
      <h3 class="text-center" v-if="flow == 'create'">
        {{ $t('Views.AdminUsers.createText') }}
      </h3>
      <h3 class="text-center" v-if="flow == 'edit'">
        {{ $t('Views.AdminUsers.editText') }}
      </h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <div class="row">
          <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row no-gutters>
                <span style="width: 30%"
                  >{{ $t('Views.AdminUsers.labelAssociation') }}:</span
                >
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminUsers.placeholder')"
                  v-model="company"
                  :options="companies"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect></v-row
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminUsers.labelName')"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="[rules.required, rules.max]"
                :label="$t('Views.AdminUsers.labelLastname')"
                v-model="lastname"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :rules="emailRules"
                :label="$t('Views.AdminUsers.labelEmail')"
                v-model="email"
                type="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                v-mask="phoneMask"
                :rules="[rules.required, rules.maxNumber]"
                :label="$t('Views.AdminUsers.labelPhone')"
                v-model="phone"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :label="$t('Views.AdminUsers.labelUsername')"
                :rules="[rules.required, rules.max]"
                v-model="username"
              ></v-text-field>
            </v-col>
            <v-col v-if="flow == 'create'" cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :label="$t('Views.AdminUsers.labelPassword')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                v-model="password"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              ></v-text-field>
            </v-col>
            <v-col v-if="flow == 'create'" cols="12" lg="4" md="6" sm="12">
              <v-text-field
                :label="$t('Views.AdminUsers.labelPasswordConfirmation')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                v-model="confirmPassword"
                counter
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-row no-gutters>
                <span style="width: 30%">{{ $t('Views.AdminUsers.labelRoles') }}:</span>
                <multiselect
                  style="width: 70%"
                  track-by="id"
                  label="name"
                  :placeholder="$t('Views.AdminUsers.placeholder')"
                  v-model="selectedRoles"
                  :options="roles"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect>
              </v-row>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox
                :label="$t('Views.AdminUsers.labelActive')"
                v-model="active"
              ></v-checkbox>
            </v-col>
          </v-form>
        </div>
      </div>
      <v-col cols="12" style="margin-bottom: 10px; height: 10%">
        <div class="row">
          <v-btn
            :dark="valid"
            style="margin: auto"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="createItem()"
          >
            {{ $t('Views.AdminUsers.createButton') }}
          </v-btn>
          <v-btn
            :dark="valid"
            style="margin: auto"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#0c2c7c"
            @click="saveItem()"
          >
            {{ $t('Views.AdminUsers.saveButton') }}
          </v-btn>

          <v-btn style="margin: auto" dark color="#0c2c7c" @click="reset">
            {{ $t('Views.AdminUsers.cleanButton') }}
          </v-btn>
          <v-btn
            dark
            style="margin: auto"
            color="#0c2c7c"
            @click="$modal.hide('crudModal')"
          >
            {{ $t('Views.AdminUsers.cancelButton') }}
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Users',
    components: {
      SimpleList,
      Multiselect,
    },
    data() {
      return {
        index: 1,
        config: {
          headers: {},
        },
        loading: false,
        roles: [
          { code: 'horizontal', name: 'Admin' },
          { code: 'vertical', name: 'Vertical' },
        ],
        items: [],
        users: [],
        companies: [],
        roles: [
          { id: 1, name: 'super_admin' },
          { id: 2, name: 'fiaf_admin' },
          { id: 3, name: 'association_admin' },
          { id: 4, name: 'publisher' },
          { id: 5, name: 'user' },
        ],
        selectedRoles: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        // orderBy: ,
        filters: {
          search: '',
          role: null,
        },
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        activeOptions: [
          { id: 1, name: 'Activos' },
          { id: 0, name: 'Inactivos' },
        ],
        active: false,
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastname: '',
        email: '',
        userId: '',
        order: 1,
        type: 'vertical',
        image: null,
        company: null,
        loadingText: 'Cargando registros',
        valid: true,
        showPassword: false,
        emailRules: [
          (v) => !!v || 'E-mail es requerido',
          (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 6 || '6 caracteres como mínimo',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          max: (v) => v.length <= 100 || 'Debe poser menos de 100 caracteres',
        },
        extraButtoms: [
          {
            text: 'Roles Asignados',
            link: 'usuarios/roles',
            icon: 'mdi-file-document-edit',
          },
        ],
      }
    },
    created() {
      this.getCompanies()
      this.getUsers()
    },
    computed: {
      ...mapGetters(['locale']),
      headers() {
        return [
          { text: this.$t('Views.AdminUsers.headerName'), value: 'name' },
          { text: this.$t('Views.AdminUsers.headerLastname'), value: 'lastname' },
          { text: this.$t('Views.AdminUsers.headerEmail'), value: 'email' },
          { text: this.$t('Views.AdminUsers.headerPhone'), value: 'phone' },
          { text: this.$t('Views.AdminUsers.headerActive'), value: 'activeText' },
          {
            text: '',
            value: 'actions',
            align: 'center',
          },
        ]
      },
      orderOptions() {
        return [
          {
            id: 1,
            name: this.$t('Views.AdminUsers.orderOption1'),
            order: 'created_at',
            by: 'desc',
          },
          {
            id: 2,
            name: this.$t('Views.AdminUsers.orderOption2'),
            order: 'created_at',
            by: 'asc',
          },
          {
            id: 3,
            name: this.$t('Views.AdminUsers.orderOption3'),
            order: 'name',
            by: 'asc',
          },
          {
            id: 4,
            name: this.$t('Views.AdminUsers.orderOption4'),
            order: 'updated_at',
            by: 'desc',
          },
        ]
      },
      orderBy() {
        return {
          id: 1,
          name: this.$t('Views.AdminUsers.orderOption1'),
          order: 'created_at',
          by: 'desc',
        }
      },
    },
    watch: {
      locale() {
        this.index++
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getUsers()
      },
      cleanFilters() {
        this.filters = {
          search: '',
          role: null,
        }
      },
      async getUsers() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let roleString = ''
          let activeString = ''
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.role && this.filters.role.name) {
            roleString = '&role=' + this.filters.role.name
          }
          if (this.filters.active && this.filters.active.id) {
            activeString = '&active=' + this.filters.active.id
          }
          queryParams = searchString + roleString + activeString
        }
        axios
          .get('users?page=' + this.page + orderString + queryParams)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.current_page
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              let activeText
              switch (x.active) {
                case true:
                  activeText = 'Si'
                  break
                case false:
                  activeText = 'No'
                  break
                default:
                  activeText = ''
                  break
              }
              x.activeText = activeText
            })
            this.loading = false
          })
      },

      async getCompanies() {
        axios.get('companies?limit=100&type=1&active=1').then((response) => {
          this.companies = this.companies.concat(response.data)
        })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.lastname = ''
        this.email = ''
        this.userId = ''
        this.password = ''
        this.confirmPassword = ''
        this.phone = ''
        this.username = ''
        this.selectedRoles = []
        this.company = null
        this.active = false

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.company = this.item.company ? this.item.company : null
        this.name = this.item.name
        this.lastname = this.item.lastname
        this.email = this.item.email
        this.phone = this.item.phone
        this.username = this.item.username
        this.selectedRoles = this.item.roles
        this.active = this.item.active

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }

        let formData = new FormData()
        formData.append('user_id', this.userId)
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('type', 0)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('username', this.username)
        if (this.company && this.company.id) {
          formData.append('company_id', this.company.id)
        }
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active)
        this.selectedRoles.forEach((role) => {
          formData.append('role[]', role.name)
        })
        axios.post('users', formData, this.config).then((response) => {
          this.page = 1
          this.getUsers()
          this.$modal.hide('crudModal')
        })
      },

      async saveItem() {
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }

        let formData = new FormData()
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('type', 0)
        formData.append('username', this.username)
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active)
        if (this.company && this.company.id) {
          formData.append('company_id', this.company.id)
        } else {
          formData.append('company_id', '')
        }
        this.selectedRoles.forEach((role) => {
          formData.append('role[]', role.name)
        })
        formData.append('_method', 'patch')
        axios.post('users/' + this.item.id, formData, this.config).then((response) => {
          this.page = 1
          this.getUsers()
          this.$modal.hide('crudModal')
        })
      },

      async deleteItem(item) {
        this.loading = true
        axios.delete('users/' + item.id).then((response) => {
          this.page = 1
          this.items = []
          this.getUsers()
        })
      },

      async pagination(page) {
        this.page = page
        this.items = []
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
